// UserProfile.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './userProfileMenu.module.less';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

interface UserProfileMenuProps {
    closeTooltip: () => void;
    setCurrent: (current: string) => void;
};

const UserProfileMenu: React.FC<UserProfileMenuProps> = ({ 
    closeTooltip, 
    setCurrent }) => {
    const navigate = useNavigate();

    //跳转到个人中心
    const toUserCenter = () => {
        navigate('/user');
        closeTooltip();
        setCurrent('UserCenter');
    }

    return (
        <div className={styles.userProfileMenu}>
            <ul>
                <li onClick={toUserCenter}>个人中心</li>
                <li onClick={() => {
                    localStorage.clear();
                    mixpanel.reset();
                    navigate('/home');
                }}>退出登录</li>
            </ul>
        </div>

    );
};

export default UserProfileMenu;
