import { genObjectIDbyTS } from '../utils/id_generator';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import DataDefinition from './DataDefinition';

class Blank extends DataDefinition {
    start: number;
    end: number;
    // choices是对应的是blanks的选项，应是一个数组
    // 为了legacy兼容，可能要改成options
    choicesStr: string;
    choices: string[];
    // 实际选的是哪个选项
    selectedChoice: string | null;
    descriptionAboutBlank: string;
    //是否允许用户自定义填空
    allowCustomInput: boolean = false;


    constructor({
        start = -1,
        end = -1,
        choicesStr = '',
        choices = [],
        selectedChoice = null,
        descriptionAboutBlank = '',
        allowCustomInput = false
    }: Partial<Blank> = {}) {
        super();
        this.allowedFields = ['start', 'end', 'choicesStr', 'choices', 'selectedChoice',
            'descriptionAboutBlank', 'allowCustomInput'];
        this.start = start;
        this.end = end;
        this.choicesStr = choicesStr;
        this.choices = choices;
        if (this.choicesStr) {
            this.setChoices(this.choicesStr);
        }
        this.selectedChoice = selectedChoice;
        this.descriptionAboutBlank = descriptionAboutBlank;
        this.allowCustomInput = allowCustomInput;
    }

    setChoices(choicesStr: string) {
        this.choices = choicesStr.split('|');
    }

};

class MediaInfo extends DataDefinition {
    mediaType: string;
    mediaUrl: string;

    constructor({
        mediaType = '',
        mediaUrl = ''
    }: Partial<MediaInfo> = {}) {
        super();
        this.allowedFields = ['mediaType', 'mediaUrl'];
        this.mediaType = mediaType;
        this.mediaUrl = mediaUrl;
    }
}


class ChatMessage extends DataDefinition {
    id: number;
    // type = 3: preset msg
    // type = 1: gpt回答
    // type = 4: gpt回答 -- media
    type: number;
    // 加入 user的信息
    userId: number;
    userName: string;
    rawContent: string;
    // responseMethod是指回答的方式， 之前的answerWay
    responseMethod: string;
    stream: boolean = true;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', Blank)
    blanks: Blank[] = [];
    @Reflect.metadata('design:type', MediaInfo)
    mediaInfo: MediaInfo | null;

    // 这个消息的内容是否用例set变量
    usedToSetVar: boolean = false;
    // 消息对应的语音
    audioUrl: string = '';
    // messageVoice是指消息的音色
    messageVoice: string = '';
    // tools, 目前是个string，需要进一步处理
    tools: string | undefined;
    doneCallFunc: Record<string, any>;
    // 消息结束后的回调函数
    postFuncCalls: string[] = [];

    shouldRepeatPreset: boolean = false;
    readStreaming: boolean = true;//是否需要读取流式音频
    streamingShow: boolean = false;//为true就无视readStreaming，此时流式展示但是不读
    context: any;
    //时间戳
    timestamp: number;


    constructor({
        id = genObjectIDbyTS(),
        type = 0,
        userId = 0,
        userName = 'default',
        rawContent = '',
        responseMethod = '',
        stream = true,
        blanks = [],
        mediaInfo = null,
        usedToSetVar = false,
        audioUrl = '',
        messageVoice = '',
        tools = undefined,
        doneCallFunc = {},
        postFuncCalls = [],
        shouldRepeatPreset = false,
        readStreaming = true,
        streamingShow = false,
        context = null,
        timestamp = Date.now()
    }: Partial<ChatMessage> = {}

    ) {
        super();
        this.allowedFields = ['id', 'type', 'userId', 'userName', 'rawContent', 'responseMethod', 'stream',
            'blanks', 'mediaInfo', 'usedToSetVar', 'audioUrl', 'messageVoice', 'tools', 'doneCallFunc',
            'postFuncCalls', 'shouldRepeatPreset', 'readStreaming', 'streamingShow', 'context', 'timestamp'];
        this.id = id;
        this.type = type;
        this.userId = userId;
        this.userName = userName;
        this.rawContent = rawContent;
        this.responseMethod = responseMethod;
        this.stream = stream;
        this.blanks = blanks;
        this.mediaInfo = mediaInfo;
        this.usedToSetVar = usedToSetVar;
        this.audioUrl = audioUrl;
        this.messageVoice = messageVoice;
        this.tools = tools;
        this.doneCallFunc = doneCallFunc;
        this.postFuncCalls = postFuncCalls;
        this.shouldRepeatPreset = shouldRepeatPreset;
        this.readStreaming = readStreaming;
        this.streamingShow = streamingShow;
        this.context = context;
        this.timestamp = timestamp;

        if (this.type === 0) {
            console.error("chatmsg type should not be 0, but this could be we are creating a new chatmsg");
        }
    }

    // 填空题，提取文本中的空
    generateBlanks() {
        const regex = /_+/g;
        let match;
        while ((match = regex.exec(this.rawContent)) !== null) {
            this.blanks.push(new Blank({ start: match.index, end: match.index + match[0].length - 1 }));
        }
    }

    genTextToSend(): string {
        let textToSend = containsVariablePattern(this.rawContent) ? rewriteText(this.rawContent) : this.rawContent;
        let renderedText = [];
        let lastIndex = 0;
        //把TEXT中的当前每个空，根据index，替换为blanks中的selected_choice
        this.blanks.forEach((blank, index) => {
            const { start, end } = blank;
            renderedText.push(textToSend.substring(lastIndex, start));
            renderedText.push(blank.selectedChoice || textToSend.substring(start, end + 1));
            lastIndex = end + 1;
        });
        renderedText.push(textToSend.substring(lastIndex));
        return renderedText.join('');
    }

    // 生成预设填空题的元数据
    static generatePredefinedChoiceMsgData(templateID: number, responseMethod: string,
        choicesStr: string, tools: string, usedToSetVar: boolean): ChatMessage {
        const msgTemplates = ["我选_______。",
            "我觉得______。"]
        const msg: ChatMessage = new ChatMessage({
            type: 2,
            rawContent: msgTemplates[templateID],
            responseMethod: responseMethod,
            stream: true,
            usedToSetVar: usedToSetVar,
        });

        const startPos = msgTemplates[templateID].indexOf("_");
        const endPos = msgTemplates[templateID].lastIndexOf("_") + 1;

        msg.blanks = [new Blank({ start: startPos, end: endPos, choicesStr: choicesStr })];


        if (responseMethod === "gpt-check") {
            msg.tools = tools;
        }

        return msg;
    }

    // 生成媒体消息数据
    static generateMediaMsgData(mediaInfo: MediaInfo): ChatMessage {
        const msg: ChatMessage = new ChatMessage({
            id: genObjectIDbyTS(),
            type: 4,
            mediaInfo: mediaInfo,
            usedToSetVar: true
        });

        return msg;
    }

};

export { Blank, ChatMessage, MediaInfo };

